@import "../../styles/variables.module";
.navbarBrand {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 75px;
  right: 0;
  left: 0;
  background: $body-color;
  width: 100%;
  .brandLink {
    position: relative;
    left: 80px;
  }
}
.login {
  height: 100vh;
  display: table;
  width: 100%;
  background: $white-color;
  .desc {
    width: 100%;
  }
  &.forgetPwd {
    .accPwd,
    .centerPart,
    .bottomPart {
      display: none !important;
    }
    .btns {
      margin-top: 50px;
    }
    .linkAccount {
      margin-top: 50px;
    }
  }
  &.signup {
    .centerPart {
      display: none !important;
    }
    .btns {
      margin-top: 50px;
    }
  }
  .forgetPwd {
    color: $theme-custom-color;
    font-size: 14px;
    line-height: 28px;
    cursor: pointer;
  }
  .btns {
    position: relative;
    margin-top: 20px;
  }
  .accountDetails {
    -webkit-box-shadow: -4px 0px 40px rgba(0, 0, 0, 0.06);
    box-shadow: -4px 0px 40px rgba(0, 0, 0, 0.06);
    padding: 80px 100px;
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    position: relative;
    z-index: 5;
    background: $white-color;
    .loginForm {
      width: 500px;
      margin: 0 auto;
      .sectionTitle {
        margin-bottom: 50px;
      }
    }
    .bold {
      color: $theme-custom-color;
    }
    .condition {
      margin: 0;
      position: relative;
      span {
        float: right;
        width: calc(100% - 25px);
      }
      input {
        width: 20px;
        height: 20px;
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: 1;
        top: 4px;
        cursor: pointer;
        &:checked {
          & + label {
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 6px;
              left: 7px;
              width: 6px;
              height: 14px;
              border: 1px solid $secondary-color;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
      label {
        position: relative;
        cursor: pointer;
        line-height: 28px;
        color: $desc-font;
        font-size: 14px;
        font-weight: 400;
        &::before {
          content: "";
          -webkit-appearance: none;
          appearance: none;
          background-color: #f2f6f8;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
        }
      }
    }
    .formGroup {
      margin-bottom: 24px;
      &.invalid {
        input {
          border: 1px solid red;
          background: #ffeded;
        }
      }
      & > label {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 32px;
      }
      .formControl {
        background: #f2f6f8;
        font-size: 14px;
        height: 40px;
        color: #888888;
        border-radius: $border-radius;
      }
    }
    .textOr {
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #f2f6f8;
        position: absolute;
        left: 0;
        right: 0;
        top: 12px;
      }
      span {
        position: relative;
        padding: 0 10px;
        background-color: $white-color;
      }
    }
    .linkAccount {
      color: $desc-font;
      font-size: 14px;
      font-weight: 400;
      margin-top: 50px;
      .text {
        color: $theme-color;
        font-weight: 600;
        text-decoration: underline;
        padding-left: 4px;
      }
    }
    .connection {
      .links {
        border: 1px solid #c4c4c4;
        border-radius: $border-radius;
        padding: 8px 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-color;
        flex: 1;
        & + .links {
          margin-left: 20px;
        }
        img {
          width: 20px;
        }
        span {
          color: $black-color;
          font-size: 14px;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }
  & > * {
    width: 50%;
    .image {
      img {
        margin: 0 auto;
      }
    }
  }
}
.showPassword {
  .icon {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 14px;
    top: 42%;
    transform: translateY(-50%);
    line-height: normal;
    cursor: pointer;
  }
}
@media screen and (max-width: 1440px) {
  .navbarBrand {
    .brandLink {
      left: 50px;
    }
  }
  .login {
    .leftImage {
      padding: 40px;
      .desc {
        margin-bottom: 10px;
      }
    }
    .accountDetails {
      padding: 40px;
      .loginForm {
        width: 400px;
      }
      .connection {
        .links {
          padding: 8px;
          span {
            font-size: 13px;
          }
        }
      }
    }
    .acoountImg {
      width: 400px;
    }
  }
}
@media screen and (max-width: 991px) {
  .navbarBrand {
    text-align: center;
    background: $white-color;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    height: 60px;
    .brandLink {
      left: 0;
      img {
        height: 60px;
      }
    }
  }
  .login {
    display: flex;
    flex-wrap: wrap;
    &.signup {
      .btns {
        margin-top: 30px;
      }
    }
    .accountDetails {
      margin-top: 60px;
      padding: 40px 20px 60px;
      width: 100%;
      display: block;
      -webkit-box-shadow: none;
      box-shadow: none;
      z-index: 1;
      .sectionTitle {
        text-align: center;
        &::before {
          margin: 0 auto;
        }
      }
      .btn {
        margin-top: 10px;
      }
      .connection {
        .links {
          padding: 6px 8px;
          span {
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
    & > * {
      .image {
        img {
          height: 220px;
        }
      }
    }
    .acoountImg {
      margin-top: 30px;
      .section-title {
        font-size: 24px;
        margin-top: 30px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .login {
    .acoountImg {
      width: 100%;
    }
    .accountDetails {
      .loginForm {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .login {
    .accountDetails {
      .connection {
        margin-bottom: 20px;
        .links {
          & + .links {
            margin-left: 10px;
          }
        }
      }
      .loginForm {
        .sectionTitle {
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .login {
    .accountDetails {
      .connection {
        .links {
          img {
            width: 16px;
          }
        }
      }
    }
  }
}
