@import "../../styles/variables.module";
.leftImage {
    background-color: $body-color;
    // padding: 40px 100px;
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    .acoountImg {
      text-align: center;
      width: 520px;
      margin: 0 auto;
      .sectionTitle {
        font-size: 36px;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 52px;
        margin-top: 60px;
      }
      [class*="-dots"] {
        li {
          width: auto;
          height: auto;
          margin: 0 4px;
          &[class*="slick-active"] {
            button {
              background: $secondary-color;
              width: 30px;
            }
          }
          button {
            width: 10px;
            height: 5px;
            border-radius: 50px;
            background-color: #c4c4c4;
            display: inline-block;
            padding: 0;
            border: none;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }